import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Col from 'reactstrap/lib/Col';
import FormGroup from 'reactstrap/lib/FormGroup';
import Row from 'reactstrap/lib/Row';

import { CartValidations, RegisterableCustomer, countriesThatRequireState } from '@ttstr/api';
import { EMAIL_PATTERN } from '@ttstr/utils';

import CountrySelect from '../Form/CountrySelect';
import FormField from '../Form/FormField';

type OwnProps =
  | {
      mode: 'create';
      showPassword?: boolean;
      onCountryChanged?(countryCode: string): void;
      hideCompany?: boolean;
      hideAddressAddition?: boolean;
      hideState?: boolean;
      overrideValidations?: CartValidations;
    }
  | {
      mode: 'modify';
      showPassword?: boolean;
      onCountryChanged?(countryCode: string): void;
      hideCompany?: boolean;
      hideAddressAddition?: boolean;
      hideState?: boolean;
      overrideValidations?: CartValidations;
    };

type Props = Readonly<OwnProps>;

const PersonalisationDataFields: React.FC<Props> = ({
  showPassword,
  mode,
  onCountryChanged,
  hideCompany,
  hideAddressAddition,
  hideState,
  overrideValidations,
}) => {
  const { t } = useTranslation();
  const { getValues, watch } = useFormContext<RegisterableCustomer>();

  const country = String(watch('country')).toUpperCase();

  return (
    <FormGroup>
      <Row>
        <Col>
          {/* First Name */}
          <FormField
            type="text"
            label={t('CUSTOMER.FIRSTNAME')}
            name="firstname"
            autoComplete="billing given-name"
            validationOptions={{ required: true }}
          />
        </Col>
        <Col>
          {/* Last Name */}
          <FormField
            type="text"
            label={t('CUSTOMER.LASTNAME')}
            name="lastname"
            autoComplete="billing family-name"
            validationOptions={{ required: true }}
          />
        </Col>
      </Row>
    </FormGroup>
  );
};

export default PersonalisationDataFields;
