import classNames from 'classnames';

import React from 'react';

import { useTranslation } from 'react-i18next';

import { Articles } from '@ttstr/api';
import { SushiProps } from '@ttstr/components/Sushi/Sushi';
import Maki from '@ttstr/components/Sushi/Maki';
import { Loading } from '@ttstr/components';
import { Filters } from '@ttstr/components/Masonry/MasonryFilter';
import MasonrySorter, { SorterOptions } from '@ttstr/components/Masonry/MasonrySorter';
import Nigiri from './Nigiri';

const MasonryFilter = React.lazy(() => import('@ttstr/components/Masonry/MasonryFilter'));

const defaultNigiriColumnClassName = 'col-12';
const defaultMakiColumnClassName = 'col-md-6 col-lg-4';

interface OwnProps {
  id?: string;
  products: Articles;
  type?: 'list' | React.ComponentType<SushiProps>;
  filters?: 'off' | (keyof Filters)[];
  sorters?: SorterOptions;
  showLocation?: boolean;
  showCity?: boolean;
  showTitle?: boolean;
  showSupertitle?: boolean;
  showSubtitle?: boolean;
  showBrand?: boolean;
  showCategories?: boolean;
  showTags?: boolean;
  showFormerPrice?: boolean;
  className?: string;
  rowClassName?: string;
  itemClassName?: string;
  itemImageClassName?: string;
  columnClassName?: string;
  sushiContainerClassName?: string;
  itemProps?: Partial<SushiProps>;
  noResultsClassName?: string;
  /**
   * disables masonry filters from replacing filter
   * querys in the URL after a timed delay.
   * This causes a bug in Safari where you can't
   * enter a new URL into the address bar.
   */
  dontUpdateQueryStrings?: boolean;
}

type Props = Readonly<OwnProps>;

const Masonry: React.FC<Props> = ({
  id,
  products,
  type,
  filters,
  sorters,
  showLocation,
  showCity,
  showTitle,
  showSupertitle,
  showSubtitle,
  showBrand,
  showCategories,
  showTags,
  showFormerPrice,
  className,
  rowClassName = 'row',
  itemClassName,
  itemImageClassName,
  columnClassName = '',
  sushiContainerClassName,
  noResultsClassName = 'text-info',
  itemProps = {},
  dontUpdateQueryStrings,
}) => {
  const { t } = useTranslation();
  // TODO
  // const eventIdToProductIds = React.useMemo(
  //   () =>
  //     products.reduce<{ [eventId: number]: number[] }>((acc, current) => {
  //       const eventId = current.event_id;
  //       if (eventId !== null) {
  //         return {
  //           ...acc,
  //           [eventId]: [...(acc[eventId] ?? []), current.id],
  //         };
  //       }
  //       return acc;
  //     }, {}),
  //   [products]
  // );
  // const productIdToEventId = React.useMemo(
  //   () =>
  //     products.reduce<{ [productId: number]: number }>((acc, current) => {
  //       const eventId = current.event_id;
  //       if (eventId !== null) {
  //         return {
  //           ...acc,
  //           [current.id]: eventId,
  //         };
  //       }
  //       return acc;
  //     }, {}),
  //   [products]
  // );

  // Test

  const [filteredSortedProducts, setFilteredSortedProducts] = React.useState(products);
  const flipKey = React.useMemo(() => JSON.stringify(filteredSortedProducts.map((p) => p.id)), [
    filteredSortedProducts,
  ]);

  const SushiElement = type === 'list' ? Nigiri : type ?? Maki;
  if (!sushiContainerClassName && type) {
    sushiContainerClassName = type === 'list' ? 'nigiri-container' : 'maki-container';
  }

  if (columnClassName === '') {
    columnClassName = type === 'list' ? defaultNigiriColumnClassName : defaultMakiColumnClassName;
  }

  return (
    <section id={id} className={className} itemScope itemType="http://schema.org/ItemList">
      <Loading>
        <MasonryFilter
          products={products}
          onUpdatedProducts={setFilteredSortedProducts}
          filters={filters}
          dontUpdateQueryStrings={dontUpdateQueryStrings}
        />
        <MasonrySorter products={products} onUpdatedSorters={setFilteredSortedProducts} sorters={sorters} />
      </Loading>
      <div
        className={classNames('masonry', rowClassName)}
      >
        {filteredSortedProducts.map((product) => (
            <article
            key={product.id}
              id={`article-${product.id}`}
              className={classNames('sushi-container', sushiContainerClassName, columnClassName)}
              itemScope
              itemProp="itemListElement"
              itemType={product.type === 'Product' ? 'http://schema.org/Product' : 'http://schema.org/Event'}
            >
              <SushiElement
                product={product}
                showLocation={showLocation}
                showCity={showCity}
                showTitle={showTitle}
                showSupertitle={showSupertitle}
                showSubtitle={showSubtitle}
                showBrand={showBrand}
                showCategories={showCategories}
                showTags={showTags}
                showFormerPrice={showFormerPrice}
                className={itemClassName}
                imageClassName={itemImageClassName}
                {...itemProps}
              />
            </article>
        ))}
        </div>
      {Boolean(products.length) && !filteredSortedProducts.length && (
        <p className={noResultsClassName}>{t(`MASONRY.SEARCH.NO_RESULTS`)}</p>
      )}
    </section>
  );
};

export default React.memo(Masonry);
